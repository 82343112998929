<template>

  <div>

    <v-expansion-panels accordion focusable>

      <v-expansion-panel v-for="(category, index) in dataForm"
                         style="border-radius: 8px;"
                         class="text-primary mb-5">
        <v-expansion-panel-header class="today-medium" style="font-size: 1.5em;" v-on:click="lockRequestService">
          {{ category.name }}
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-4">
          <v-row justify="center" class="pt-4">
            <v-col cols="12" md="12">
              <v-form
                  ref="form"
                  lazy-validation>

                <v-row>


                <div v-for="(dataField, i) in category.fields" :class="dataField.field_appearance === 'address' ? 'col-md-3 col-lg-3 col-12 py-0' : 'col-md-12 col-12 py-0'" >

                  <v-text-field
                      v-if="!['select_options', 'radio', 'title', 'separator', 'file', 'currency'].includes(dataField.type)"
                      :type="dataField.type"
                      :label="dataField.name"
                      :value="dataField.value"
                      :rules="validationFields(dataField.validation)"
                      :ref="'id-' + dataField.id"
                      outlined
                      dense
                      color="primary"
                      class="input-field"
                      :prefix="dataField.description.length <= 2 ? dataField.description : ''"
                      v-on:blur="changeInput($event, dataField)"></v-text-field>


<!--                  <v-text-field
                      v-if="['currency'].includes(dataField.type)"
                      type="number"
                      :label="dataField.name"
                      :value="dataField.value"
                      :rules="validationFields(dataField.validation)"
                      outlined
                      dense
                      color="primary"
                      class="input-field"
                      v-on:blur="changeInput($event, dataField.id)"></v-text-field>-->

                  <currency-input v-if="['currency'].includes(dataField.type) && !isSafari"
                                  :ref="'id-' + dataField.id"
                                  :options="{
                                    locale: 'en-US',
                                    currency: 'USD',
                                    currencyDisplay: 'symbol',
                                    hideCurrencySymbolOnFocus: false,
                                    hideGroupingSeparatorOnFocus: false,
                                    hideNegligibleDecimalDigitsOnFocus: true,
                                    autoDecimalDigits: false,
                                    autoSign: true,
                                    useGrouping: true,
                                    accountingSign: false
                                  }"
                                  @change="changeInput($event, dataField)"
                                  :rules="validationFields(dataField.validation)"
                                  :label="dataField.name"
                                  :value="dataField.value"/>

                  <currency-input v-if="['currency'].includes(dataField.type) && isSafari"
                                  :ref="'id-' + dataField.id"
                                  :options="{
                                    locale: 'en-US',
                                    currency: 'USD',
                                    currencyDisplay: 'symbol',
                                    hideCurrencySymbolOnFocus: false,
                                    hideGroupingSeparatorOnFocus: false,
                                    hideNegligibleDecimalDigitsOnFocus: true,
                                    autoDecimalDigits: false,
                                    autoSign: true,
                                    useGrouping: true,
                                    accountingSign: false
                                  }"
                                  v-on:input="changeInput($event, dataField)"
                                  :rules="validationFields(dataField.validation)"
                                  :label="dataField.name"
                                  :value="dataField.value"/>

                  <!--                                  v-on:input="changeInput($event, dataField)"-->

                  <div v-if="dataField.type == 'select_options' && dataField.data_source_description == null">

                    <v-select
                        v-if="dataField.name.length > 45"
                        :items="dataField.options"
                        item-text='name'
                        item-value='key'
                        :label="dataField.name"
                        :value="dataField.value"
                        :rules="validationFields(dataField.validation)"
                        append-outer-icon="mdi-information-outline"
                        @click:append-outer="testAppend(dataField.name)"
                        outlined
                        dense
                        color="primary"
                        class="select-field"
                        v-on:change="changeSelect($event, dataField)"
                    ></v-select>

                    <div v-else>
                      <v-select
                          v-if="dataField.type == 'select_options' && dataField.data_source_description == null"
                          :items="dataField.options"
                          item-text='name'
                          item-value='key'
                          :label="dataField.name"
                          :value="dataField.value"
                          :rules="validationFields(dataField.validation)"
                          outlined
                          dense
                          color="primary"
                          class="select-field"
                          v-on:change="changeSelect($event, dataField)"
                      ></v-select>
                    </div>

                  </div>

                  <div v-if="dataField.type == 'select_options' && dataField.data_source_description != null">

                    <v-select
                        v-if="dataField.name.length > 45"
                        :items="settingsDataSelect(dataField.data_source.split('.')[1], dataField)"
                        :item-text="dataField.data_source_description.label"
                        :item-value="dataField.data_source_description.value"
                        :label="dataField.name"
                        :value="dataField.value"
                        :rules="validationFields(dataField.validation)"
                        :id="''+dataField.id"
                        append-outer-icon="mdi-information-outline"
                        @click:append-outer="testAppend(dataField.name)"
                        outlined
                        dense
                        color="primary"
                        class="select-field"
                        v-on:change="changeSelectNested($event, dataField)"
                    ></v-select>

                    <div v-else>
                      <v-select
                          :items="settingsDataSelect(dataField.data_source.split('.')[1], dataField)"
                          :item-text="dataField.data_source_description.label"
                          :item-value="dataField.data_source_description.value"
                          :label="dataField.name"
                          :value="dataField.value"
                          :rules="validationFields(dataField.validation)"
                          :id="''+dataField.id"
                          outlined
                          dense
                          color="primary"
                          class="select-field"
                          v-on:change="changeSelectNested($event, dataField)"
                      ></v-select>
                    </div>

                  </div>

                  <v-radio-group v-model="radioGroup" v-if="dataField.type == 'radio'">
                    <template v-slot:label>
                      <div>{{ dataField.name }}</div>
                    </template>
                    <v-radio
                        v-for="n in dataField.options"
                        :key="n.key"
                        :label="n.name"
                        :value="n"
                    ></v-radio>
                  </v-radio-group>

                  <v-file-input
                      v-if="dataField.type == 'file'"
                      :label="dataField.name"
                      outlined
                      dense
                      color="primary"
                  ></v-file-input>

                  <v-divider v-if="dataField.type == 'separator'"></v-divider>

                  <div v-if="dataField.type == 'title'">
                    <v-card-title>{{ dataField.name }}</v-card-title>
                    <v-card-subtitle>{{ dataField.description }}</v-card-subtitle>
                  </div>

                  <div v-if="dataField.type == 'select_options' && dataField.data_source_description == null">
                    <div v-for="(selectField, i) in dataField.options">
                      <div v-if="dataField.value == selectField.key && selectField.fields.length > 0">

                        <v-row>

                        <div v-for="(dinamicField) in selectField.fields" :class="dinamicField.field_appearance === 'address' ? 'col-md-3 col-lg-3 col-12 py-0' : 'col-md-12 col-12 py-0'">
                          <v-text-field
                              v-if="!['select_options', 'radio', 'title', 'separator', 'file', 'currency'].includes(dinamicField.type)"
                              :type="dinamicField.type"
                              :label="dinamicField.name"
                              :value="dinamicField.value"
                              :rules="validationFields(dinamicField.validation)"
                              outlined
                              dense
                              color="primary"
                              class="input-field"
                              :prefix="dinamicField.description.length <= 2 ? dinamicField.description : ''"
                              v-on:blur="changeInput($event, dinamicField)"></v-text-field>


<!--                          <v-text-field
                              v-if="['currency'].includes(dinamicField.type)"
                              type="number"
                              :label="dinamicField.name + ' xx'"
                              :value="dinamicField.value"
                              :rules="validationFields(dinamicField.validation)"
                              outlined
                              dense
                              color="primary"
                              class="input-field"
                              v-on:blur="changeInput($event, dinamicField)"></v-text-field>-->

                          <currency-input v-if="['currency'].includes(dinamicField.type) && !isSafari"
                                          :options="{
                                            locale: 'en-US',
                                            currency: 'USD',
                                            currencyDisplay: 'symbol',
                                            hideCurrencySymbolOnFocus: false,
                                            hideGroupingSeparatorOnFocus: false,
                                            hideNegligibleDecimalDigitsOnFocus: true,
                                            autoDecimalDigits: false,
                                            autoSign: true,
                                            useGrouping: true,
                                            accountingSign: false
                                          }"
                                          v-on:change="changeInput($event, dinamicField)"
                                          :rules="validationFields(dinamicField.validation)"
                                          :label="dinamicField.name"
                                          :value="dinamicField.value"/>

                          <currency-input v-if="['currency'].includes(dinamicField.type) && isSafari"
                                          :options="{
                                            locale: 'en-US',
                                            currency: 'USD',
                                            currencyDisplay: 'symbol',
                                            hideCurrencySymbolOnFocus: false,
                                            hideGroupingSeparatorOnFocus: false,
                                            hideNegligibleDecimalDigitsOnFocus: true,
                                            autoDecimalDigits: false,
                                            autoSign: true,
                                            useGrouping: true,
                                            accountingSign: false
                                          }"
                                          v-on:input="changeInput($event, dinamicField)"
                                          :rules="validationFields(dinamicField.validation)"
                                          :label="dinamicField.name"
                                          :value="dinamicField.value"/>

                          <v-select
                              v-if="dinamicField.type == 'select_options' && dinamicField.data_source_description == null"
                              :items="dinamicField.options"
                              item-text='name'
                              item-value='key'
                              :label="dinamicField.name"
                              :value="dinamicField.value"
                              :rules="validationFields(dinamicField.validation)"
                              outlined
                              dense
                              color="primary"
                              class="select-field"
                              v-on:change="changeSelect($event, dinamicField)"
                          ></v-select>

                          <div v-if="dinamicField.type == 'select_options' && dinamicField.data_source_description != null">

                            <v-select
                                v-if="settingsDataSelect(dinamicField.data_source.split('.')[1], dinamicField)"
                                :items="settingsDataSelect(dinamicField.data_source.split('.')[1], dinamicField)"
                                :item-text="dinamicField.data_source_description.label"
                                :item-value="dinamicField.data_source_description.value"
                                :label="dinamicField.name"
                                :value="dinamicField.value"
                                :rules="validationFields(dinamicField.validation)"
                                :id="''+dinamicField.id"
                                outlined
                                dense
                                color="primary"
                                class="select-field"
                                v-on:change="changeSelectNested($event, dinamicField)"
                            ></v-select>

                            <v-text-field
                                v-else
                                type="text"
                                :label="dinamicField.name"
                                :value="dinamicField.value"
                                :rules="validationFields(dinamicField.validation)"
                                outlined
                                dense
                                color="primary"
                                class="input-field"
                                v-on:blur="changeInput($event, dinamicField)"></v-text-field>
                          </div>


                          <v-radio-group v-model="radioGroup" v-if="dinamicField.type == 'radio'">
                            <template v-slot:label>
                              <div>{{ dinamicField.name }}</div>
                            </template>
                            <v-radio
                                v-for="n in dinamicField.options"
                                :key="n.key"
                                :label="n.name"
                                :value="n"
                            ></v-radio>
                          </v-radio-group>

                          <v-file-input
                              v-if="dinamicField.type == 'file'"
                              :label="dinamicField.name"
                              outlined
                              dense
                              color="primary"
                          ></v-file-input>

                          <v-divider v-if="dinamicField.type == 'separator'"></v-divider>

                          <div v-if="dinamicField.type == 'title'">
                            <v-card-title>{{ dinamicField.name }}</v-card-title>
                            <v-card-subtitle>{{ dinamicField.description }}</v-card-subtitle>
                          </div>

                          <div v-if="dinamicField.type == 'select_options' && dinamicField.data_source_description == null">
                            <div v-for="(sonSelectField, i) in dinamicField.options" >
                              <div v-if="dinamicField.value == sonSelectField.key && sonSelectField.fields.length > 0">

                                <div>
                                <div v-for="(dinamicField2) in sonSelectField.fields" :class="dinamicField2.field_appearance === 'address' ? 'col-md-3 col-lg-3 col-12 py-0' : 'col-md-12 col-12 py-0 px-0'">
                                  <v-text-field
                                      v-if="!['select_options', 'radio', 'title', 'separator', 'file', 'currency'].includes(dinamicField2.type)"
                                      :type="dinamicField2.type"
                                      :label="dinamicField2.name"
                                      :value="dinamicField2.value"
                                      :rules="validationFields(dinamicField2.validation)"
                                      outlined
                                      dense
                                      color="primary"
                                      class="input-field"
                                      :prefix="dinamicField2.description.length <= 2 ? dinamicField2.description : ''"
                                      v-on:blur="changeInput($event, dinamicField2)"></v-text-field>

                                  <!--                          <v-text-field
                                                                v-if="['currency'].includes(dinamicField.type)"
                                                                type="number"
                                                                :label="dinamicField.name + ' xx'"
                                                                :value="dinamicField.value"
                                                                :rules="validationFields(dinamicField.validation)"
                                                                outlined
                                                                dense
                                                                color="primary"
                                                                class="input-field"
                                                                v-on:blur="changeInput($event, dinamicField)"></v-text-field>-->

                                  <currency-input v-if="['currency'].includes(dinamicField2.type)"
                                                  :options="{
                                                    locale: 'en-US',
                                                    currency: 'USD',
                                                    currencyDisplay: 'symbol',
                                                    hideCurrencySymbolOnFocus: false,
                                                    hideGroupingSeparatorOnFocus: false,
                                                    hideNegligibleDecimalDigitsOnFocus: true,
                                                    autoDecimalDigits: false,
                                                    autoSign: true,
                                                    useGrouping: true,
                                                    accountingSign: false
                                                  }"
                                                  v-on:change="changeInput($event, dinamicField2)"
                                                  :rules="validationFields(dinamicField2.validation)"
                                                  :label="dinamicField2.name"
                                                  :value="dinamicField2.value"/>

                                  <v-select
                                      v-if="dinamicField2.type == 'select_options' && dinamicField2.data_source_description == null"
                                      :items="dinamicField2.options"
                                      item-text='name'
                                      item-value='key'
                                      :label="dinamicField2.name"
                                      :value="dinamicField2.value"
                                      :rules="validationFields(dinamicField2.validation)"
                                      outlined
                                      dense
                                      color="primary"
                                      class="select-field"
                                      v-on:change="changeSelect($event, dinamicField2)"
                                  ></v-select>

                                  <div v-if="dinamicField2.type == 'select_options' && dinamicField2.data_source_description != null">

                                    <v-select
                                        v-if="settingsDataSelect(dinamicField2.data_source.split('.')[1], dinamicField2)"
                                        :items="settingsDataSelect(dinamicField2.data_source.split('.')[1], dinamicField2)"
                                        :item-text="dinamicField2.data_source_description.label"
                                        :item-value="dinamicField2.data_source_description.value"
                                        :label="dinamicField2.name"
                                        :value="dinamicField2.value"
                                        :rules="validationFields(dinamicField2.validation)"
                                        :id="''+dinamicField2.id"
                                        outlined
                                        dense
                                        color="primary"
                                        class="select-field"
                                        v-on:change="changeSelectNested($event, dinamicField2)"
                                    ></v-select>

                                    <v-text-field
                                        v-else
                                        type="text"
                                        :label="dinamicField2.name"
                                        :value="dinamicField2.value"
                                        :rules="validationFields(dinamicField2.validation)"
                                        outlined
                                        dense
                                        color="primary"
                                        class="input-field"
                                        v-on:blur="changeInput($event, dinamicField2)"></v-text-field>
                                  </div>

                                  <v-radio-group v-model="radioGroup" v-if="dinamicField2.type == 'radio'">
                                    <template v-slot:label>
                                      <div>{{ dinamicField2.name }}</div>
                                    </template>
                                    <v-radio
                                        v-for="n in dinamicField2.options"
                                        :key="n.key"
                                        :label="n.name"
                                        :value="n"
                                    ></v-radio>
                                  </v-radio-group>

                                  <v-file-input
                                      v-if="dinamicField2.type == 'file'"
                                      :label="dinamicField2.name"
                                      outlined
                                      dense
                                      color="primary"
                                  ></v-file-input>

                                  <v-divider v-if="dinamicField2.type == 'separator'"></v-divider>

                                  <div v-if="dinamicField2.type == 'title'">
                                    <v-card-title>{{ dinamicField2.name }}</v-card-title>
                                    <v-card-subtitle>{{ dinamicField2.description }}</v-card-subtitle>
                                  </div>

                                </div>

                                </div>

                              </div>
                            </div>
                          </div>

                        </div>


                        </v-row>


                      </div>
                    </div>
                  </div>

                </div>


                </v-row>

              </v-form>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

    </v-expansion-panels>


    <v-snackbar
        v-model="snackbarTest"
        :timeout="4000"
        bottom
        right
        color="orange accent-2"
    >
      <div v-html="snackbarMessage"></div>
    </v-snackbar>

    <v-snackbar
        v-if="!isSafari"
        v-model="snackbar"
        :timeout="4000"
        bottom
        right
        color="red accent-2"
    >
      <div v-html="snackbarMessage"></div>
    </v-snackbar>

    <v-snackbar
        v-model="snackbarInfo"
        :timeout="10000"
        shaped
        color="blue-grey lighten-5"
        light
        absolute
    >
      {{ messageInfo }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="red"
            text
            @click="snackbarInfo = false"
        >
          Cerrar
        </v-btn>
      </template>

    </v-snackbar>




<!--    <v-text-field
        ref="inputRef"
        type="text"
        label="prueba"
        :value="0"
        required
        outlined
        dense
        color="primary"
        class="input-field"
        v-currency></v-text-field>-->


<!--    <v-text-field
        v-if="['currency'].includes(dataField.type)"
        type="text"
        :label="dataField.name"
        :value="dataField.value"
        required
        outlined
        dense
        color="primary"
        class="input-field"
        v-on:focusout="changeInput($event, dataField)"
        v-currency="{currency: 'USD', locale: 'en'}"></v-text-field>-->


  </div>

</template>

<script>
import {locals} from "@/helpers/locals";
import {api} from "@/helpers/axios";
import {constants} from "@/helpers/constants";
import CurrencyInput from "@/components/CurrencyInput";
import {useCurrencyInput} from 'vue-currency-input'

export default{
  name: 'Fields',
  components: {CurrencyInput, useCurrencyInput},
  props: ['dataForm'],

  directives: {
    currency: useCurrencyInput
  },
  data() {
    return {
      errorValidation: true,
      rules: {
        required: value => !!value || 'Campo obligatorio',
        counterMax: value => value.length <= 10 || 'Cantidad máxima 10 digitos',
        counterMin: value => value.length == 10 || 'Cantidad minima 10 digitos',
        email: value => /.+@.+/.test(value) || 'Formato de email invalido',
      },
      countries: null,
      countryZones: null,
      countryCities: null,
      settings: null,
      searchInput: '',
      request: null,
      snackbar: false,
      loading: false,
      snackbarMessage: '',
      validations: [],
      assets: 0,
      passives: 0,
      snackbarTest: false,
      snackbarInfo: false,
      messageInfo: '',
      requestServices: false,  // no permite llamar los ser icios al abrir las categorias
      dataCurrentForm: null,
      isSafari: false
    }
  },
  created() {
    this.settings = locals.getLocal('settings');
    this.request = locals.getLocal('request');
    this.countries = this.settings.countries;
    this.countryZones = this.settings.country_zones;
    this.countryCities = this.settings.country_cities;

    // REALIZA LA VALIDACION DEL FORMULARIO
    //console.log('this.dataForm: ', this.dataForm);
    locals.setLocal('dataCurrentForm', JSON.stringify({}));

    this.interval = setInterval(() => {

      try {
        if(this.dataForm != null) {
          clearInterval(this.interval);
          this.getDataCurrentForm();
        }
      } catch (e) {
        console.log(e);
      }

    }, 500);

    this.testBrowser();

  },


  methods: {

    testBrowser() {
      var sBrowser, sUsrAg = navigator.userAgent;

      if(sUsrAg.indexOf("Chrome") > -1) {
        this.isSafari = false;
        sBrowser = "Google Chrome";
      } else if (sUsrAg.indexOf("Safari") > -1) {
        this.isSafari = true;
        sBrowser = "Apple Safari";
      } else if (sUsrAg.indexOf("Opera") > -1) {
        this.isSafari = false;
        sBrowser = "Opera";
      } else if (sUsrAg.indexOf("Firefox") > -1) {
        this.isSafari = false;
        sBrowser = "Mozilla Firefox";
      } else if (sUsrAg.indexOf("MSIE") > -1) {
        this.isSafari = false;
        sBrowser = "Microsoft Internet Explorer";
      }
      console.log(sUsrAg, navigator.platform, navigator.appName, navigator.appCodeName, navigator.onLine);

    },

    getDataCurrentForm() {

      this.dataCurrentForm = locals.getLocal('dataCurrentForm');
      this.dataCurrentForm['user_request_id'] = this.request.id;
      locals.setLocal('dataCurrentForm', JSON.stringify(this.dataCurrentForm));

      this.dataForm.forEach((category) => {
        category.fields.forEach((field) => {
          //console.log('value field: ', field.value);

          if(field.value != '') {
            let nameField = 'field_' + field.id;
            this.dataCurrentForm[nameField] = field.value;
          }

          field.options.forEach((selectField) => {

            if(field.value == selectField.key && selectField.fields.length > 0) {

              selectField.fields.forEach((dinamicField) => {
                if(dinamicField.value != '') {
                  let nameField = 'field_' + dinamicField.id;
                  this.dataCurrentForm[nameField] = dinamicField.value;
                }

                dinamicField.options.forEach((sonSelectField) => {

                  if(dinamicField.value == sonSelectField.key && sonSelectField.fields.length > 0) {

                    sonSelectField.fields.forEach((dinamicField2) => {
                      if(dinamicField2.value != '') {
                        let nameField = 'field_' + dinamicField2.id;
                        this.dataCurrentForm[nameField] = dinamicField2.value;
                      }

                    });
                  }

                });

              });

            }

          });

        });
      });

      locals.setLocal('dataCurrentForm', JSON.stringify(this.dataCurrentForm));

    },

    lockRequestService() {
      console.log('entra : lockRequestService');
      this.requestServices = false;
      setTimeout(() => {
        this.requestServices = !this.requestServices;
      }, 900);
    },

    testAppend(textQuestion) {
      this.snackbarInfo = true;
      this.messageInfo = textQuestion;
    },

    testCurrency(eventText) {
      console.log('testCurrency : ' + eventText);
      this.snackbarTest = true;
      this.snackbarMessage = 'prueba : ' + eventText;

      const { inputRef } = useCurrencyInput({
        locale: 'en-US',
        currency: 'USD',
        currencyDisplay: 'symbol',
        hideCurrencySymbolOnFocus: false,
        hideGroupingSeparatorOnFocus: false,
        hideNegligibleDecimalDigitsOnFocus: true,
        autoDecimalDigits: false,
        autoSign: true,
        useGrouping: true,
        accountingSign: false
      });

      return inputRef;

    },

    settingsDataSelect: function (data, fieldData) {

      //console.log('entra : settingsDataSelect', fieldData, fieldData.id);
      // Validacion de datos para ver si tiene campos anidados
      let newSettingsDataSource = [];
      if(fieldData.related_field_id != 0){
        this.dataForm.forEach((category) => {
          category.fields.forEach((field) => {

            if (field.id == fieldData.related_field_id) {
              console.log(field.id, fieldData.related_field_id, field.data_source.split('.')[1]);
              this.settings[field.data_source.split('.')[1]].forEach((dataSource1) => {
                if (dataSource1.value == field.value) {
                  this.settings[data].forEach((settingsItem) => {
                    if (dataSource1.key == settingsItem.key) {
                      newSettingsDataSource.push(settingsItem);
                    }
                  });
                }
              });

            } else {
              field.options.forEach((selectField) => {

                selectField.fields.forEach((dynamicField) => {
                  if (dynamicField.id == fieldData.related_field_id) {
                    //console.log('settingsDataSelect - validacionCamposDinamicos', selectField.id, dynamicField.id, dynamicField, fieldData.related_field_id, selectField.fields, field.id, fieldData);
                    this.settings[dynamicField.data_source.split('.')[1]].forEach((dataSource1) => {
                      if (dataSource1.value == dynamicField.value) {
                        this.settings[data].forEach((settingsItem) => {
                          if (dataSource1.key == settingsItem.key) {
                            newSettingsDataSource.push(settingsItem);
                          }
                        });
                      }
                    });

                  }
                });

              });
            }

          });
        });
      }
      return newSettingsDataSource.length > 0 ? newSettingsDataSource : this.settings[data];
    },
    validationFields(validation) {
      let validations = [];
      if(validation.search('required') !== -1) {
        validations.push(this.rules.required);
      }
      if(validation.search('email') !== -1) {
        validations.push(this.rules.email);
      }

      //validations.push(this.rules.customRule);

      return validations;

    },
    changeInput(event, field) {

      if(this.requestServices) {

        console.log('changeInput: ', event, field);

        let value = '';
        let validate = false;
        if(field.type == 'currency') {

          if(typeof event === 'object' &&
              !Array.isArray(event) &&
              event !== null) {
            value = event.target.value;
          } else {
            value = event;
          }

          validate = true;

          // 39 -> Activos | 40 -> Pasivos
          if(field.id == 39) {
            this.assets = value;
          }
          if(field.id == 40) {
            this.passives = value;
          }

          if(this.passives > 0 && this.assets > 0) {
            this.total_assets = this.assets - this.passives;

            this.dataForm.forEach((category) => {
              category.fields.forEach((field) => {

                // 41 -> total patrimonio
                if (field.id == 41) {
                  field.value = this.total_assets.toString();
                  this.saveField(this.total_assets, 41);
                }

              });
            });

          }

        } else {
          value = event.target.value;
          validate = event.target.validity.valid;
        }

        /*if(value !== '' && value != null && validate) {
          this.saveField(value, field.id);
        }*/

        this.saveField(value, field.id);

      }


    },
    changeSelect(event, field) {
      let value = event;

      this.lockRequestService();

      this.dataForm.forEach((category) => {
        category.fields.forEach((fieldData) => {
          if (fieldData.id == field.id) {
            fieldData.value = event;
          }

          // nuevo
          fieldData.options.forEach((selectField) => {

            selectField.fields.forEach((dynamicField) => {
              if (dynamicField.id == field.id) {
                console.log('validacionCamposDinamicos : changeSelect', event, selectField.id, selectField.fields, field.id, fieldData, fieldData.value == selectField.key, selectField.fields);
                console.log('formulario: ', this.dataForm);
                dynamicField.value = event;
              }
            });

          });


        });
      });

      if(value != '' && value != null) {
        this.saveField(value, field.id);
      }
    },
    changeSelectNested(event, field) {
      let value = event;
      if(value != '' && value != null) {
        this.saveField(value, field.id);
      }

      this.dataForm.forEach((category) => {
        category.fields.forEach((fieldData) => {
          //console.log(event, fieldData.id, field.id, fieldData.id == field.id, fieldData);
          if (fieldData.id == field.id) {
            fieldData.value = event;
          }

          fieldData.options.forEach((selectField) => {

            selectField.fields.forEach((dynamicField) => {
              if (dynamicField.id == field.id) {
                console.log('validacionCamposDinamicos : changeSelectNested', selectField.id, selectField.fields, field.id, fieldData, fieldData.value == selectField.key, selectField.fields);
                dynamicField.value = event;
              }
            });

          });

        });
      });

    },
    saveField(value, fieldId) {

      this.dataCurrentForm = locals.getLocal('dataCurrentForm');
      let nameField = 'field_' + fieldId;
      this.dataCurrentForm[nameField] = value;
      this.dataCurrentForm['user_request_id'] = this.request.id;

      locals.setLocal('dataCurrentForm', JSON.stringify(this.dataCurrentForm));

      this.request = locals.getLocal('request');
      api.post(constants.endPoints.saveForm, {
        field_id: fieldId,
        user_request_id: this.request.id,
        value: value
      }, true)
        .then(response => {
          console.log('saveField', response);

          try {
            console.log('field id-' + fieldId + ' : ', this.$refs, this.$refs[`id-${fieldId}`][0], this.$refs[`id-${fieldId}`][0].$refs.inputRef.error);

            this.$refs[`id-${fieldId}`][0].$refs.inputRef.error = false;
            this.$refs[`id-${fieldId}`][0].$refs.inputRef.errorMessages = [];

          } catch (e) {
            console.log('catch', e.message);
          }

        }).catch(function (error) {
          //
          console.log('errors: saveField ', error,  error.message);

          try {
            let status = error.response.status;
            if(status == 401){
              locals.clearLocal();
              this.$router.push('/');
            }
            let errors = error.response.data.errors;
            let message = '';
            let messageValidation = '';
            for (var [key, value] of Object.entries(errors.value)) {
              console.log(key + ' : ' + value);
              message = message + value + '<br>';
              messageValidation = messageValidation + value + " | ";
            }
            console.log('field id-' + fieldId + ' : ', this.$refs, this.$refs[`id-${fieldId}`][0], this.$refs[`id-${fieldId}`][0].$refs, messageValidation);

            this.snackbarMessage = 'Valide la informacion:' + '<br>' + message;
            this.snackbar = true;
            this.loading = false;

            this.$refs[`id-${fieldId}`][0].$refs.inputRef.error = true;
            this.$refs[`id-${fieldId}`][0].$refs.inputRef.errorMessages = [messageValidation.substring(0, messageValidation.length - 2)];

          } catch (e) {
            console.log('catch', e.message);

            this.snackbarMessage = '[' + fieldId + ']' +'[' + value + ']' + error.message;
            this.snackbar = true;
            this.loading = false;

            //locals.clearLocal();
            //this.$router.push('/');
          }

      }.bind(this))
    }
  }
}
</script>

<style>

.v-expansion-panel-header:before {
  background-color: #0E4A7D !important;
}

.v-text-field .v-label--active {
  max-width: 100% !important;
}



</style>
