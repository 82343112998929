<template>
  <div class="contact-data fill-height">

    <v-row class="fill-height">
      <left-side-image/>
      <v-col cols="12" md="7">

        <nav-bar-page/>

        <v-container>

          <v-row align="center" justify="center" class="mt-2">
            <v-col cols="12" class="mt-0 py-0">
              <div class="my-0 pt-0 black--text">
                <h1 class="text-center today-regular text-title" >VALIDA TU PERFIL</h1>
              </div>
            </v-col>
            <v-col cols="11" md="6" class="mt-0 py-0">
              <div class="my-0 pt-0 primary--text">
                <h3 class="primary--text text-center mb-0 today-light line-height-1">Responde a continuación las siguientes preguntas para finalizar tu proceso de identificación.</h3>
              </div>
            </v-col>

            <v-col cols="12" md="10" lg="10">

<!--              <div  class="px-14 pb-2 mt-8">
                <h3 class="primary-text text-center mb-0 today-light line-height-1">{{ messageResult }}</h3>
              </div>-->

              <v-col class="text-center justify-center">
                <v-progress-circular
                    v-if="!noQuestions"
                    :size="50"
                    color="primary"
                    indeterminate
                ></v-progress-circular>
              </v-col>

              <div v-if="noQuestions" class="px-14 pb-2 mt-8">
                <v-form
                    ref="form"
                    lazy-validation
                    @submit.prevent="validateQuestions">

                  <div class="pt-0 primary--text mb-2">
                    <h4 class="primary--text today-light line-height-1 question-style">{{ capitalize(dataQuestionsServiceEvidente.Pregunta[0].texto) }}</h4>
                  </div>
                  <v-select
                      v-model="q1"
                      :items="dataQuestionsServiceEvidente.Pregunta[0].Respuesta"
                      item-text='texto'
                      item-value='id'
                      :rules="[v => !!v || 'Selecciona el campo']"
                      required
                      outlined
                      dense
                      color="primary"
                      class="select-field"
                  ></v-select>

                  <div class="pt-0 primary--text mb-2">
                    <h4 class="primary--text today-light line-height-1 question-style">{{ capitalize(dataQuestionsServiceEvidente.Pregunta[1].texto) }}</h4>
                  </div>
                  <v-select
                      v-model="q2"
                      :items="dataQuestionsServiceEvidente.Pregunta[1].Respuesta"
                      item-text='texto'
                      item-value='id'
                      :rules="[v => !!v || 'Selecciona el campo']"
                      required
                      outlined
                      dense
                      color="primary"
                      class="select-field"
                  ></v-select>

                  <div class="pt-0 primary--text mb-2">
                    <h4 class="primary--text today-light line-height-1 question-style">{{ capitalize(dataQuestionsServiceEvidente.Pregunta[2].texto) }}</h4>
                  </div>
                  <v-select
                      v-model="q3"
                      :items="dataQuestionsServiceEvidente.Pregunta[2].Respuesta"
                      item-text='texto'
                      item-value='id'
                      :rules="[v => !!v || 'Selecciona el campo']"
                      required
                      outlined
                      dense
                      color="primary"
                      class="select-field"
                  ></v-select>

                  <div class="pt-0 primary--text mb-2">
                    <h4 class="primary--text today-light line-height-1 question-style">{{ capitalize(dataQuestionsServiceEvidente.Pregunta[3].texto) }}</h4>
                  </div>
                  <v-select
                      v-model="q4"
                      :items="dataQuestionsServiceEvidente.Pregunta[3].Respuesta"
                      item-text='texto'
                      item-value='id'
                      :rules="[v => !!v || 'Selecciona el campo']"
                      required
                      outlined
                      dense
                      color="primary"
                      class="select-field"
                  ></v-select>

                  <v-layout justify-center pt-4 mt-6>
                    <v-card-actions>
                      <v-btn
                          :loading="loading"
                          :disabled="loading"
                          color="primary"
                          type="submit"
                          class="px-12 text-capitalize vardi-button">
                        Continuar
                      </v-btn>
                    </v-card-actions>
                  </v-layout>

                </v-form>
              </div>

            </v-col>
          </v-row>

        </v-container>
      </v-col>
    </v-row>


    <v-dialog
        v-model="dialogErrorEvidente"
        max-width="400"
        overlay-color="primary"
    >
      <v-card class="pa-4 text-center">

        <v-card-title class="justify-center text-uppercase font-weight-bold today-medium">
          Lo sentimos
        </v-card-title>

        <h3 class="text-justify primary--text today-light line-height-1 px-12 pt-4">{{ messageResult }}</h3>

        <div class="px-12 pb-6 mt-4">
          <v-form
              ref="form"
              lazy-validation
              @submit.prevent="logout()">

            <v-layout justify-center pt-4>
              <v-card-actions>
                <v-btn
                    :loading="loading"
                    :disabled="loading"
                    color="primary"
                    type="submit"
                    class="px-12 text-capitalize vardi-button">
                  Finalizar
                </v-btn>
              </v-card-actions>
            </v-layout>

          </v-form>
        </div>

      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialogErrorAnswerEvidente"
        max-width="400"
        overlay-color="primary"
    >
      <v-card class="pa-4 text-center">

        <v-card-title class="justify-center text-uppercase font-weight-bold today-medium">
          Lo sentimos
        </v-card-title>

        <h3 class="text-justify primary--text today-light line-height-1 px-12 pt-4">{{ messageResult }}</h3>

        <div class="px-12 pb-6 mt-4">
          <v-form
              ref="form"
              lazy-validation
              @submit.prevent="reValidateEvidente">

            <v-layout justify-center pt-4>
              <v-card-actions>
                <v-btn
                    :loading="loading"
                    :disabled="loading"
                    color="primary"
                    type="submit"
                    class="px-12 text-capitalize vardi-button">
                  Aceptar
                </v-btn>
              </v-card-actions>
            </v-layout>

          </v-form>
        </div>

      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialogRestrictions"
        max-width="400"
        overlay-color="primary"
    >
      <v-card class="pa-4 text-center">

        <v-img max-height="50"
               class="my-4"
               contain
               :src="dataDialogRestrictions.modal.image"
        ></v-img>

        <v-card-title class="justify-center text-uppercase font-weight-bold today-medium">
          {{ dataDialogRestrictions.modal.title }}
        </v-card-title>

        <h3 class="text-justify primary--text today-light line-height-1 px-12">{{ dataDialogRestrictions.modal.content }}</h3>


        <div class="px-12 pb-6 mt-4">
          <v-form
              ref="form"
              lazy-validation
              @submit.prevent="logout()">

            <v-layout justify-center pt-4>
              <v-card-actions>
                <v-btn
                    :loading="loading"
                    :disabled="loading"
                    color="primary"
                    type="submit"
                    class="px-12 text-capitalize vardi-button">
                  Finalizar
                </v-btn>
              </v-card-actions>
            </v-layout>

          </v-form>
        </div>

      </v-card>
    </v-dialog>

    <v-snackbar
        v-model="snackbar"
        :timeout="4000"
        bottom
        right>
      <div v-html="snackbarMessage"></div>
    </v-snackbar>

  </div>
</template>

<script>
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import NavBarPage from "@/components/Layouts/NavBarPage";
import Fields from "@/components/Form/Fields";
import {api} from "@/helpers/axios";
import {constants, services} from "@/helpers/constants";
import {locals} from "@/helpers/locals";

export default {
  name: 'ValidateProfile',
  components: {LeftSideImage, NavBarPage, Fields},
  data() {
    return {
      payDialog: false,
      loadingCellphone: false,
      loading: false,
      codeSent: false,
      firstFeeValue: 0,
      creditStudy: 15000,
      insurance: 2365,
      ivaValue: 2143,
      othersFeeValue: 0,
      amortizedFee: 0,
      admin: 10000,
      totalValuePolicy: 3500000,
      discountValue: 175716,
      valueFinance: 3324284,
      feeNumber: 2,
      rate: 1.25,
      feeValue: 0,
      expires_in: 60,
      countdown: '',
      dataForm: null,
      snackbar: false,
      snackbarMessage: '',
      rules: {
        required: value => !!value || 'Ingresa la información',
        counterMax: value => value.length <= 10 || 'Cantidad máxima 10 digitos',
        counterMin: value => value.length == 10 || 'Cantidad minima 10 digitos'
      },
      user: null,
      noQuestions: false,
      dataQuestionsServiceEvidente: null,
      q1: '',
      q2: '',
      q3: '',
      q4: '',
      dataQuestionsServiceEvidenteDummy: {
        "Pregunta": [
          {
            "Respuesta": [
              {
                "id": "001",
                "texto": "00 - 30"
              },
              {
                "id": "002",
                "texto": "31 - 39"
              },
              {
                "id": "003",
                "texto": "40 - 48"
              },
              {
                "id": "004",
                "texto": "49 - 57"
              },
              {
                "id": "005",
                "texto": "58 - 99"
              }
            ],
            "id": "00000042",
            "idRespuestaCorrecta": "00",
            "orden": "1",
            "peso": "1",
            "texto": "SU EDAD EN CUAL DE LOS SIGUIENTES RANGOS SE ENCUENTRA"
          },
          {
            "Respuesta": [
              {
                "id": "001",
                "texto": "4628015"
              },
              {
                "id": "002",
                "texto": "4628025"
              },
              {
                "id": "003",
                "texto": "4629025"
              },
              {
                "id": "004",
                "texto": "11 - 15   AÑOS"
              },
              {
                "id": "005",
                "texto": "16 O MAS  AÑOS"
              },
              {
                "id": "006",
                "texto": "NINGUNA DE LAS ANTERIORES"
              }
            ],
            "id": "009002002",
            "idRespuestaCorrecta": "00",
            "orden": "2",
            "peso": "1",
            "texto": "DE LOS SIGUIENTES TELEFONOS  INDIQUE CON CUAL USTED HA TENIDO O TIENE ALGUN VINCULO"
          },
          {
            "Respuesta": [
              {
                "id": "001",
                "texto": "CLASICA/ESTANDAR"
              },
              {
                "id": "002",
                "texto": "ORO/GOLD"
              },
              {
                "id": "003",
                "texto": "PLANTINO/PLATINUM"
              },
              {
                "id": "004",
                "texto": "EMPRESARIAL/BUSSINES"
              },
              {
                "id": "005",
                "texto": "NO TENGO TARJETA DE CREDITO CON LA ENTIDAD"
              }
            ],
            "id": "009002002",
            "idRespuestaCorrecta": "00",
            "orden": "3",
            "peso": "1",
            "texto": "DE QUE TIPO ES SU TARJETA DE CREDITO DE ''"
          },
          {
            "Respuesta": [
              {
                "id": "001",
                "texto": "ESTABA ABIERTA/VIGENTE"
              },
              {
                "id": "002",
                "texto": "ESTABA CANCELADA/SALDADA/CERRADA"
              },
              {
                "id": "003",
                "texto": "NUNCA HE TENIDO CUENTA CORRIENTE CON LA ENTIDAD"
              }
            ],
            "id": "004001002",
            "idRespuestaCorrecta": "00",
            "orden": "4",
            "peso": "2",
            "texto": "EN AGOSTO DE 2018 SU CUENTA CORRIENTE CON ''"
          }],
          "alertas": "false",
          "codigoAlerta": "00",
          "excluirCliente": "false",
          "id": "00000042",
          "registro": "366094",
          "respuestaAlerta": "",
          "resultado": "01"
      },
      dataAnswersServiceEvidente: null,
      messageResult: 'Consultando...',
      dialogErrorEvidente: false,
      dataDialogRestrictions: {
        modal: {
          image: '',
          title:'',
          content:'',
          buttons:
              {
              }
        }
      },
      dialogRestrictions: false,
      dataDialogDefault: {
        modal: {
          image: 'https://app-biocredit.s3.amazonaws.com/images/coopcentral/pagina-web.png',
          title:'¡LO SENTIMOS!',
          content:'El usuario no cumple con las políticas de la entidad. Comunícate con un asesor.',
          buttons:
              {
                text: 'Finalizar',
                action: 'logout'
              }
        }
      },
      dialogErrorAnswerEvidente: false,

    }
  },
  mounted() {
    this.user = locals.getLocal('user');

    //this.noQuestions = true;
    //this.dataQuestionsServiceEvidente = this.dataQuestionsServiceEvidenteDummy;

    //this.capitalize('HOLA ESTO ES UNA PRUEBA');

    this.getQuestions();

    //this.validateQuestions();
  },
  methods: {

    logout() {
      services.logout();
      this.$router.push('/');
    },

    getQuestions() {
      if (this.user.reg_validation != '') {

        api.post(constants.endPoints.questionsServiceEvidente, {
          reg_validation: this.user.reg_validation,
        }, true)
            .then(response => {
              console.log('questionsServiceEvidente', response);

              this.user.reg_validation = '';
              locals.setLocal('user', JSON.stringify(this.user));

              this.dataQuestionsServiceEvidente = response.data.data;

              // resultado
              // 00 Usuario no tiene activa la opción de última consulta ó no hubo resultados en la consulta
              // 01 Preguntas generadas con éxito.
              // 02 Error en el proceso de generación de preguntas ó cuestionario ya generado para ese
              // número de validación.
              // 07 No hay suficientes preguntas.
              // 10 Se han agotado el número de intentos permitidos por día
              // 11 Se han agotado el número de intentos permitidos por este mes
              // 12 Se han agotado el número de intentos permitidos por este año
              // 13 Se han agotado el número de ingresos permitidos al producto por día
              // 14 Se han agotado el número de ingresos permitidos para el producto por este mes
              // 15 Se han agotado el número de ingresos permitidos para el producto por este año
              // 17 Consulta no autorizada - t1 *
              // 18 Consulta no autorizada – t2 **
              // 19 Consulta no autorizada – t3 ***
              // 20 El Cuestionario ya fue generado (no puede existir el mismo número de cuestionario
              // para dos transacciones diferentes)
              // 21 Este cuestionario tiene un proceso OTP que no ha sido completado ó El número de
              // identificación asociado a la solicitud no coincide con el que está asociado al
              // regValidacion

              this.noQuestions = false;

              switch (this.dataQuestionsServiceEvidente.resultado) {
                case '00':
                  this.messageResult = 'Usuario no tiene activa la opción de última consulta ó no hubo resultados en la consulta';
                  this.dialogErrorEvidente = true;
                  break;
                case '01':
                  this.noQuestions = true;
                  break;
                case '02':
                  this.messageResult = 'Error en el proceso de generación de preguntas ó cuestionario ya generado para ese número de validación';
                  this.dialogErrorEvidente = true;
                  //this.reValidateEvidente();
                  break;
                case '07':
                  this.messageResult = 'No hay suficientes preguntas';
                  this.dialogErrorEvidente = true;
                  break;
                case '10':
                  this.messageResult = 'Se han agotado el número de intentos permitidos por día';
                  this.dialogErrorEvidente = true;
                  break;
                case '11':
                  this.messageResult = 'Se han agotado el número de intentos permitidos por este mes';
                  this.dialogErrorEvidente = true;
                  break;
                case '12':
                  this.messageResult = 'Se han agotado el número de intentos permitidos por este año';
                  this.dialogErrorEvidente = true;
                  break;
                case '13':
                  this.messageResult = 'Se han agotado el número de ingresos permitidos al producto por día';
                  this.dialogErrorEvidente = true;
                  break;
                case '14':
                  this.messageResult = 'Se han agotado el número de ingresos permitidos para el producto por este mes';
                  this.dialogErrorEvidente = true;
                  break;
                case '15':
                  this.messageResult = 'Se han agotado el número de ingresos permitidos para el producto por este año';
                  this.dialogErrorEvidente = true;
                  break;
                case '17':
                  this.messageResult = 'Consulta no autorizada - t1 *';
                  this.dialogErrorEvidente = true;
                  break;
                case '18':
                  this.messageResult = 'Consulta no autorizada – t2 **';
                  this.dialogErrorEvidente = true;
                  break;
                case '19':
                  this.messageResult = 'Consulta no autorizada – t3 ***';
                  this.dialogErrorEvidente = true;
                  break;
                case '20':
                  this.messageResult = 'El Cuestionario ya fue generado (no puede existir el mismo número de cuestionario para dos transacciones diferentes)';
                  this.dialogErrorEvidente = true;
                  break;
                case '21':
                  this.messageResult = 'Este cuestionario tiene un proceso OTP que no ha sido completado ó El número de identificación asociado a la solicitud no coincide con el que está asociado al regValidacion';
                  this.dialogErrorEvidente = true;
                  break;
                default:
                  this.messageResult = 'Sin estado de respuesta';
                  this.dialogErrorEvidente = true;
                  break;
              }

            }).catch(function (error) {
          //
          console.log('errors: ', error);

          try {
            let status = error.response.status;
            if(status == 401){
              locals.clearLocal();
              this.$router.push('/');
            }
            let errors = error.response.data.errors;
            let message = '';
            for (var [key, value] of Object.entries(errors)) {
              console.log(key + ' : ' + value);
              message = message + value + '<br>';
            }
            this.snackbarMessage = message;
            this.snackbar = true;
            this.loading = false;
          } catch (e) {
            console.log(e);
          }

        }.bind(this))

      } else {
        this.reValidateEvidente();
      }

    },

    reValidateEvidente() {

      this.dialogErrorAnswerEvidente = false
      this.noQuestions = false;

      api.get(constants.endPoints.validateServiceEvidente, true)
          .then(response => {
            console.log('validateServiceEvidente', response);
            this.dataValidateEvidente = response.data.data;

            this.user.reg_validation = this.dataValidateEvidente.regValidacion;
            locals.setLocal('user', JSON.stringify(this.user));

            if(this.dataValidateEvidente.resultadoProceso === 'true') { // 'Indica si se pudo realizar o no el proceso de validación'
              if(this.dataValidateEvidente.valFechaExp === 'true') {   // 'Indica si la validación de la fecha de expedición para cédula de ciudadanía fue exitosa o no'

                switch (this.dataValidateEvidente.resultado) {  // Si el proceso fue exitoso indica el código resultado de la validación.

                  case '01':
                  case '05':

                    if(this.dataValidateEvidente.alertas == 'true') {
                      /* respuestaAlerta
                       00 Error general, comunicarse con el área encargada (servicio al cliente), error en los
                       parámetros de la parametria (alerta y cuestionario) sobre la base de datos.

                       01 Código que indica se debe generar el siguiente flujo (cuestionario o iniciar transacción
                       OTP) y él ciudadano presenta alertas

                       02 Código que indica no se debe generar el siguiente flujo (cuestionario o iniciar
                       transacción OTP) y él ciudadano presenta alertas

                       03 Código que indica que el ciudadano no presenta alertas y se debe continuar con el
                       siguiente flujo (cuestionario o iniciar transacción OTP)

                       04 Código que indica que el sistema de alertas está inactivo y se debe continuar con el
                       siguiente flujo (cuestionario o iniciar transacción OTP)
                      */
                      switch (this.dataValidateEvidente.respuestaAlerta) {
                        case '00':
                        case '02':
                          this.dataDialogRestrictions = this.dataDialogDefault;
                          this.dialogRestrictions = true;
                          break;
                        case '01':
                        case '03':
                        case '04':
                          this.getQuestions();
                          break;

                      }
                    } else {
                      this.getQuestions();
                    }

                    break;

                  case '06':

                    this.dataDialogRestrictions = this.dataDialogDefault;
                    this.dataDialogRestrictions.modal.content = 'Se sugiere no continuar el proceso debido a que la validación de identidad no fue exitosa, los nombres y/o apellidos digitados no concuerdan con el número de identificación digitado';
                    this.dialogRestrictions = true;

                    break;
                  case '07':

                    this.dataDialogRestrictions = this.dataDialogDefault;
                    this.dataDialogRestrictions.modal.content = 'Se sugiere no continuar el proceso debido a que la validación de identidad no fue exitosa, debido a que el número de identificación digitado no se encuentra relacionado en la información del buró';
                    this.dialogRestrictions = true;

                    break;
                  case '08':

                    this.dataDialogRestrictions = this.dataDialogDefault;
                    this.dataDialogRestrictions.modal.content = 'Se sugiere no continuar el proceso debido a que la validación de identidad no fue exitosa, debido a que el número de identificación digitado presenta un estado diferente a vigente en la información del buró.';
                    this.dialogRestrictions = true;

                    break;
                  case '09':

                    this.dataDialogRestrictions = this.dataDialogDefault;
                    this.dataDialogRestrictions.modal.content = 'Se sugiere no continuar el proceso debido a que la validación de identidad no fue exitosa, y por parametria la entidad ha relacionado el tope máximo s diario a realizar validaciones fallidas.';
                    this.dialogRestrictions = true;

                    break;
                  case '11':

                    this.dataDialogRestrictions = this.dataDialogDefault;
                    this.dataDialogRestrictions.modal.content = 'Se sugiere no continuar el proceso debido a que la validación de identidad no fue exitosa';
                    this.dialogRestrictions = true;

                    break;
                  default:
                    console.log('entra a default', this.dataValidateEvidente, this.dataValidateEvidente.resultado)

                }

              } else {
                // SE DEBE ACTUALIZAR LA FECHA DE EXPEDICION
                this.dialogExpeditionDate = true;
                this.registerCellphoneDialog = false;
                this.dialogRestrictions = false;
              }
            } else {
              this.dataDialogRestrictions = this.dataDialogDefault;
              this.dialogRestrictions = true;
            }

          }).catch(function (error) {
        let message = '';

        let errors = error.response.data;
        console.log('errors (validateServiceEvidente): ', error, error.response);

        if(errors.code == 400) {
          message = errors.message;
          this.snackbar = true;
          this.loading = false;
        } else {
          for (var [key, value] of Object.entries(errors)) {
            console.log(key + ' : ' + value);
            message = message + value + '<br>';
          }
        }
        this.snackbarMessage = message;
        this.snackbar = true;
        this.loading = false;

      }.bind(this));

    },

    initializeOtpServiceEvidente() {

      api.post(constants.endPoints.initializeOtpServiceEvidente, {
        reg_validation: this.dataValidateEvidente.regValidacion
      }, true)
          .then(response => {

            console.log('initializeOtpServiceEvidente', response);

            this.dataIinitializeOtpServiceEvidente = response.data.data;

            /*
              Flag booleano para confirmar si el proceso fue ejecutado satisfactoriamente
              true -> Ejecutar método GENERAR CODIGO OTP
              false -> Ejecutar método PREGUNTAS
            */
            if(this.dataIinitializeOtpServiceEvidente.ResultadoGeneracion.resultadoOTP) { // otp
              this.generateOtpCodeServiceEvidente();
            } else {    // preguntas
              this.questionsServiceEvidente();
            }


          }).catch(function (error) {

        let errors = error.response.data.errors;
        console.log('errors: ', error);
        let message = '';
        for (var [key, value] of Object.entries(errors)) {
          console.log(key + ' : ' + value);
          message = message + value + '<br>';
        }
        this.snackbarMessage = message;
        this.snackbar = true;
        this.loading = false;

      }.bind(this))

    },

    generateOtpCodeServiceEvidente() {

      api.post(constants.endPoints.generateOtpCodeServiceEvidente, {
        reg_validation: this.dataValidateEvidente.regValidacion,
        cellphone: this.user.cellphone,
        uuid_transaction: this.dataIinitializeOtpServiceEvidente.ResultadoGeneracion.idTransaccionOTP
      }, true)
          .then(response => {

            console.log('generateOtpCodeServiceEvidente', response);

            this.dataGenerateOtpCodeServiceEvidente = response.data.data;

            /*
            ResultadoGeneracion/codResultadoOTP
            3 OTP NO APROBADO POR RECONOCER - FALSE - Ejecutar método PREGUNTAS
            4 OTP SIN RESPUESTA - TRUE - Ejecutar método Verificar OTP.
            7 TRANSACCION INVALIDADA POR INTENTO ADICIONAL - FALSE - Ejecutar método Validar
            99 ERROR GENERANDO CODIGO OTP - FALSE - Ejecutar método PREGUNTAS
            */

            switch (this.dataGenerateOtpCodeServiceEvidente.ResultadoGeneracion.codResultadoOTP) {
              case '3':
              case '99':
                //this.sendCellphoneTwilio();
                this.getQuestions();
                break;
              case '4': // aparece el modal del codigo OTP
                this.startCountDown();
                this.registerCellphoneDialog = true;
                this.codeSent = true;
                break;
              case '7':     // MOSTRAR ERROR Y DE INTENTOS POR DIA Y HACER LOGOUT | CESAR
                this.validateServiceEvidente();
                break;
            }

          }).catch(function (error) {

        let errors = error.response.data.errors;
        console.log('errors: ', error);
        let message = '';
        for (var [key, value] of Object.entries(errors)) {
          console.log(key + ' : ' + value);
          message = message + value + '<br>';
        }
        this.snackbarMessage = message;
        this.snackbar = true;
        this.loading = false;

      }.bind(this))

    },

    sendCellphoneTwilio: function () {
      let validate = this.$refs.form.validate();
      if(validate) {
        this.loadingCellphone = true;

        api.get(constants.endPoints.phoneIsRegistered, true)
            .then(response => {
              console.log(response.data.status, response.data.data);
              //this.policies = response.data.data;
              //console.log(this.policies);

              if(response.data.status == true) {
                this.registerCellphoneDialog = false;

                this.getQuestions();

              } else {
                this.registerCellphoneDialog = true;

                api.post(constants.endPoints.registerPhone, {
                  cellphone: this.cellphone
                }, true)
                    .then((response) => {
                      console.log(response);
                      //this.request.id = response.data.data.id;

                      // Se inicia la cuenta atras
                      this.startCountDown();
                      this.loadingCellphone = false;
                      this.codeSent = true;

                      this.codeForTwilio = true;
                      this.registerCellphoneDialog = false;

                    }, (error) => {
                      console.log('post', error, error.message, error.code);

                      if(error.code == 422) {
                        this.getQuestions();
                      }
                    });

              }
            });

      }
    },

    validateQuestions() {

      this.loading = true;

      //this.dataQuestionsServiceEvidente = this.dataQuestionsServiceEvidenteDummy;
      console.log('validateQuestions');

      if(this.q1 != '' && this.q2 != '' && this.q3 != '' && this.q4 != '') {
        var data = new FormData();
        data.append('id_quiz', this.dataQuestionsServiceEvidente.id);
        data.append('reg_quiz', this.dataQuestionsServiceEvidente.registro);
        data.append('answers[0][id_question]', this.dataQuestionsServiceEvidente.Pregunta[0].orden);
        data.append('answers[0][id_answer]', this.q1);
        data.append('answers[1][id_question]', this.dataQuestionsServiceEvidente.Pregunta[1].orden);
        data.append('answers[1][id_answer]', this.q2);
        data.append('answers[2][id_question]', this.dataQuestionsServiceEvidente.Pregunta[2].orden);
        data.append('answers[2][id_answer]', this.q3);
        data.append('answers[3][id_question]', this.dataQuestionsServiceEvidente.Pregunta[3].orden);
        data.append('answers[3][id_answer]', this.q4);

        api.postFormData(constants.endPoints.answersServiceEvidente, data, true)
          .then(response => {
            console.log('answersServiceEvidente', response);
            this.dataAnswersServiceEvidente = response.data.data;

            this.loading = false;

            if(this.dataAnswersServiceEvidente.aprobacion == 'true') {
              this.$router.push('/formulario');
            } else {

              this.q1 = '';
              this.q2 = '';
              this.q3 = '';
              this.q4 = '';

              this.dialogErrorAnswerEvidente = true;
              this.messageResult = 'Las respuestas de validación fueron incorrectas. Inténtalo de nuevo.';

              //this.reValidateEvidente();
            }

          }).catch(function (error) {

            console.log('errors: ', error);
            this.loading = false;

          }.bind(this))
      } else {
        this.loading = false;
        this.snackbar = true;
        this.snackbarMessage = 'Debes completar todas las preguntas';
      }

    },

    capitalize(str) {
      console.log('capitalize', str.charAt(0).toUpperCase() + str.slice(1).toLowerCase());
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

  },
  created: function() {
    this.request = locals.getLocal('request');
    this.user = locals.getLocal('user');
  }
}
</script>

<style lang="scss">
.v-stepper__label {
  font-size: 12px;
}

.active-step .v-stepper__label {
  color: #0E4A7D !important;
}

.active-step > span  {
  min-width: 12px !important;
  height: 12px !important;
  width: 12px !important;
  background: #0E4A7D !important;
}

.grey-step > span {
  min-width: 12px !important;
  height: 12px !important;
  width: 12px !important;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin-top: 29px !important;
}

.active-separator-step {
  border-color: #0E4A7D !important;
}

.grey-separator-step {
  border-color: rgba(0, 0, 0, 0.38) !important;
}

.scroll-container {
  height: 400px;
  overflow: scroll;
  overflow-x: hidden;
}

.v-divider {
  border-width: 1px 2px 2px !important;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin: 29px -82px 0 !important;
}

@media (max-width: 600px) {
  .v-stepper--alt-labels .v-stepper__step {
    flex-basis: 120px !important;
    padding: 5px !important;
  }
  .v-divider {
    visibility: hidden;
  }
}


</style>